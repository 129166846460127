<template>
  <section id="detallada-test">
    <b-row class="mb-3">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t('testDetailed.title') }}</h1>
        <h5 class="text-primary">
          {{ $t('testDetailed.subtitle') }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <h4>{{ question }}</h4>
      </b-col>
      <b-col>
        <router-link
          to="/contents/resources"
          class="d-flex justify-content-end"
        >
          <b-button variant="danger">
            {{ $t('dataGeneric.goBack') }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>
    <b-card
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>{{ $t('statistics.title') }}</b-card-title>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in statisticsItems"
            :key="item.icon"
            md="3"
            sm="6"
            class="mb-2 mb-md-0"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <!-- TODO WATCH VMODEL FILTER -->
        <b-card-title>{{ $t('testDetailed.res') }}</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row class="align-items-center mb-2">
          <b-col md="4">
            <b-form-input
              v-model="userTerm"
              :disabled="unknown"
              :placeholder="$t('dataGeneric.search')"
              type="text"
              class="d-inline-block"
              @keyup="onKeyUp($event)"
            />
          </b-col>
          <b-col md="1">
            <b-button
              variant="primary"
              :disabled="unknown"
              @click="getTestInfo()"
            >
              <feather-icon
                color="white"
                icon="SearchIcon"
                size="16"
              />
            </b-button>
          </b-col>
          <b-col md="4">
            <b-form-select
              v-model="correctFilter"
              :options="filterOptions"
              @change="getTestInfo()"
            />
          </b-col>
          <b-col md="3">
            <b-form-checkbox
              v-model="unknown"
              @change="getTestInfo()"
            >
              {{ $t('userAno') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mb-2 mb-md-0"
          >
            <b-table
              :fields="fields"
              :items="usersAnswers"
              responsive="sm"
            >
              <template #cell(user)="data">
                <span>{{ data.item.user }}</span>
              </template>

              <template #cell(correct)="data">
                <b-badge
                  pill
                  :variant="data.item.correct ? 'success' : 'danger'"
                >
                  <span v-if="data.item.correct">{{ $t('testDetailed.correct') }}</span>
                  <span v-else>{{ $t('testDetailed.incorrect') }}</span>
                </b-badge>
              </template>
              <template #cell()="data">
                {{ data.value }}
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BBadge, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BTable,
  BFormInput, BFormSelect, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, isEmpty, cleanUsername } from '@/store/functions'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormCheckbox,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BTable,
    BBadge,
    BFormInput,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      question: null,
      usersAnswers: [],
      totalOk: 0,
      totalKo: 0,
      statisticsItems: [],
      fields: [
        // { key: 'index', label: 'Indice' },
        { key: 'user', label: this.$t('User'), sortable: true },
        { key: 'option', label: this.$t('Opcion'), sortable: true },
        { key: 'correct', label: this.$t('testDetailed.answer'), sortable: true },
      ],

      unknown: false,
      userTerm: '',
      correctFilter: null,
      filterOptions: [
        { value: null, text: this.$t('testDetailed.filter') },
        { value: true, text: this.$t('testDetailed.resC') },
        { value: false, text: this.$t('testDetailed.resin') },
      ],
      chartOptions: {
        labels: [this.$t('testDetailed.resC'), this.$t('testDetailed.resin')],
        chart: {
          width: 50,
          type: 'donut',
        },
        colors: ['#28c76f', '#ea5455'],
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            size: 50,
          },
        },
        stroke: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'gradient',
        },
        legend: {
          show: false,
        },
        segmentShowStroke: false,
      },
      series: [60, 40],
    }
  },
  computed: {

  },
  mounted() {
    this.getTestInfo()
    this.getStatistics()
  },
  methods: {
    onKeyUp(event) {
      const keycode = event.keyCode || event.which

      if (keycode === 13) {
        this.getTestInfo()
      }
    },

    getTestInfo() {
      const { id } = this.$route.params
      const query = `{
                answersTest(test:"${id}",username:"${this.userTerm}"${this.unknown ? ',user_Isnull:true' : ''} ${isEmpty(this.correctFilter) ? '' : `,result:${this.correctFilter}`}) {
                edges {
                    node {
                    id
                    test{
                    name
                    }
                    answer {
                        id
                        response
                    }
                    user {
                        id
                        firstName
                        lastName
                        email
                        username
                    }
                    result
                    createdAt
                    }
                }
                }
            }`
      axios.post('', { query }).then(res => {
        messageError(res, this)

        const data = res.data.data.answersTest.edges
        this.usersAnswers = data.map(item => ({
          user: !isEmpty(item.node.user) ? this.cleanUsername(item.node.user.username) : this.$t('anonimo'),
          option: item.node.answer.response,
          correct: item.node.result,
        }))
      }).catch(err => {
        console.log(err)
        this.usersAnswers = []
      })
    },
    getStatistics() {
      const { id } = this.$route.params
      const query = `
            query {
                allTests(id:"${id}"){
                    edges {
                        node{
                            id
                            name
                            testUseranswer{
                                edges{
                                    node {
                                        id
                                        answer {
                                            id
                                            response
                                        }
                                        user {
                                            id
                                            firstName
                                            lastName
                                            email
                                            username
                                        }
                                        result
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
      axios.post('', { query }).then(res => {
        messageError(res, this)

        const data = res.data.data.allTests.edges[0].node
        this.question = data.name
        data.testUseranswer.edges.forEach(item => {
          if (item.node.result) {
            this.totalOk += 1
          } else {
            this.totalKo += 1
          }
        })
        this.statisticsItems = [
          {
            icon: 'TrendingUpIcon',
            color: 'light-primary',
            title: this.totalOk,
            subtitle: this.$t('testDetailed.resC'),
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'UserIcon',
            color: 'light-info',
            title: this.totalKo,
            subtitle: this.$t('testDetailed.resin'),
            customClass: 'mb-2 mb-xl-0',
          },
        ]
      }).catch(err => {
        console.log(err)
      })
    },
    cleanUsername,
  },
}
</script>

<style></style>
